<template>
  <v-container>
    <v-row>
    <v-col cols="12" sm="12">
        <h1  class="title">
                        
                        </h1>
    </v-col>


 <v-dialog
      v-model="resume_sent"
      overlay-opacity="0.9"
      content-class="yyyyyyyyyyyyy"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
         
        </v-card-title>

        <v-card-text>
         رزومه شما با موفقیت ثبت شد
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

        

          <v-btn
            color="green darken-1"
            text
            @click="resume_sent = false"
          >
            تایید
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



       <v-dialog  
      v-model="dialog"
         overlay-opacity="0.9"
         content-class="resume"
      :transition="transition"
      max-width="920px"
    >

      <div class="job-card"> 
                  <span class="modal-title">ارسال رزومه برای موقعیت شغلی     {{$store.state.job.title}}</span>
          <span class="modal-description">اگر مهارت‌ها و توانایی‌های شما برای این فرصت شغلی مناسب است، لطفن برای ما رزومه بفرستید.</span>
  
 <v-form
      ref="form"
      @submit.prevent="submit"
    >
          <v-container>
            <v-row>
              <v-col class="pb-0"
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                outlined
              :rules="rules.name"
                hint="نام و نام خانوادگی خود را وارد کنید"
                  prepend-inner-icon="mdi-account"
                  name="firstname"
                    type="text"
                  v-model="name"
                  label="نام"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="pb-0"
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                   prepend-inner-icon="mdi-cellphone"
                        name="mobile"
                     type="tell"
                     :rules="rules.mobile"
                outlined
                required
                v-model="mobile"
                  label="شماره همراه"
                ></v-text-field>
              </v-col>
              <v-col class="pb-0"
                cols="12"
                sm="6"
                md="6"
              >

         
                <v-text-field
                    v-model="email"
                         name="email"
                      type="email"
                       :rules="rules.email"
                     prepend-inner-icon="mdi-email"
                outlined
                required
                  label="ایمیل"
                  hint=""
   
                  
                ></v-text-field>
              </v-col>
        

          <v-col class="pb-0"
                cols="12"
                sm="6"
                md="6"
              >
                 <v-file-input
                 chips
                 persistent-hint
               hint="فرمت فایل رزومه باید PDF باشد  "
                 accept="application/pdf"
            required
          @change="files"
        prepend-inner-icon="mdi-file-account"
    label="فایل رزومه"
    outlined
    
  ></v-file-input>
              </v-col>

      


             <v-col
                cols="12"
                sm="12"
                md="12"
              >
                    <v-textarea
                         name="description"
                      v-model="description"
          outlined
          label="توضیحات"
        
        ></v-textarea>

         </v-col>

        
   

           
            </v-row>
          </v-container>
   


     <v-btn
            class="send-job"
        :disabled="loading || name.length == 0 || !(new RegExp('^(\\+98|0)?9\\d{9}$').test(mobile)) ||  !validate_email(email) || file == ''"
      @click="loading = true;send_resume()"
          >
          
              <v-progress-circular  v-show="loading"
      indeterminate
      color="white"
    ></v-progress-circular>
<span v-show="!loading">
            ارسال رزومه
</span>
          </v-btn>
          
 </v-form>
   

   
      </div>
     
    </v-dialog>



              <v-col cols="12" sm="12">
                <ul class="job-tags">
                  <li v-if="$store.state.departments.length > 0 && job.length  > 0"> {{job[0].department | depart($store.state.departments)}}</li>
                  <li v-if="job.length  > 0">  {{job[0].time}}</li>
                </ul>

  
  <v-btn
            class="send-resume "
          @click="dialog = true"
      
          >
            ارسال رزومه
          </v-btn>

     <a class="whatsapp web job" href="https://web.whatsapp.com/send?phone=989020280335&text=" target="_blank">
       
        <img   :src="require('../assets/images/whatsapp.png')"  />
        ارسال اطلاعات در واتساپ
       </a>
       
    

              </v-col>
                <v-col v-if="job.length  > 0" cols="12" sm="12">
                  <div v-if="job[0].skills.length  > 0" class="job-content skills">
<h2>مهارت‌های لازم</h2>
<div v-html="job[0].skills"></div>
                  </div>
                    <div  v-if="job[0].expectations.length  > 0" class="job-content expectations">
<h2> شرح انتظارات</h2>
<div v-html="job[0].expectations"></div>
                  </div>
                    <div v-if="job[0].items.length  > 0"  class="job-content items">
<h2> موارد الزامی</h2>
<div v-html="job[0].items"></div>

                  </div>


  <v-btn 
            class="send-resume-full"
            @click="dialog = true"
          >
            ارسال رزومه
          </v-btn>

   <a class="whatsapp mobile job" href="https://api.whatsapp.com/send?phone=989020280335&text=" target="_blank">
       
           <img   :src="require('../assets/images/whatsapp.png')"  />
           ارسال اطلاعات در واتساپ
       </a>

                </v-col>

                     <v-col  cols="12" sm="12">
<div class="copy-right">

   <v-img 
          :src="require('../assets/logo-r.svg')"
          
        />

<div class="content">
<p>©1400 - ایران</p>
<p class="infos">
طراحی و توسعه توسط تیم 
<span>نهال‌گشت</span>
</p>
</div>
</div>


                     </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Job',
      created() {
   
      },
 mounted: function () {




// this.$on('jobs', function() {
 
// })
          

     window.scrollTo(0,0)


    if(window.innerWidth < 768){
this.transition = 'dialog-bottom-transition'
    }else{
this.transition = 'fade-transition'
    }
    console.log(window.innerWidth)
  },

    methods:{

files(event){
 
  if(event == null){
this.file = ''
  }else{
this.file = event
  }


},
 previewFiles(event) {
      console.log(event);
   },

validate_email(email){
return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(String(email).toLowerCase())
},

      send_resume(){


var formData = new FormData();

 formData.append('file', this.file);

const _this = this

         let senddata = {  data: {  data: ['insert', 'resumes', {name:this.name,mobile:this.mobile,email:this.email,description:this.description,job:_this.$store.state.job.title}] }, actions: ['insert'] };
 this.$http.post(_this.$store.state.url,{state:JSON.stringify( senddata )}).then((response) => {
   console.log(response);

// if(response.data != 'false'){


  this.$http.post(_this.$store.state.url+'?picid='+response.data+'&root=resumes',formData,
    {header:{
     'Content-Type' : 'multipart/form-data'
    }
    }).then((data) => {
console.log(data)
if(data.data == 'uploaded'){

_this.loading = false
_this.dialog = false
_this.resume_sent = true

}

  })


// }else{

// }

      })


      }

    },



  computed: {
    job: function () {
     const _this = this
     var jobs = []
     if(this.$store.state.jobs.length > 0 && this.$store.state.departments.length > 0){

      jobs =  this.$store.state.jobs.filter(function (e) {
    
           return e.slug == _this.$route.params.slug;
     
});
  document.title = "استخدام "+ jobs[0].title +" در نهال گشت ";
 _this.$store.state.job = jobs[0]
this.$ga.page({
  page: '/position/'+_this.$route.params.slug,
  title: document.title,
  location: window.location.href
})

     }
 
return jobs
     
    }
  },

      filters: {
  depart: function (value,departments) {
  if (!value) return ''
       var depart =  departments.filter(function (e) {
           return e.id == value;
});

return depart[0].title
  },

  },

    data: () => ({

       rules: {
          mobile: [val => ((val || '' ).length > 0 && new RegExp('^(\\+98|0)?9\\d{9}$').test(val)) || 'لطفا شماره همراه معتبر وارد کنید'],
          email: [val => ((val || '' ).length > 0 && new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(String(val).toLowerCase())) || 'لطفا  ایمیل معتبر وارد کنید'],
          file: [val => (val || '').length > 0 || 'This field is required'],
          name: [val => (val || '').length > 0 || 'این فیلد اجباری است'],
        },
 loader: null,
        loading: false,
   dialog: false,
   description :'',
    name : '',
    file:'',
    resume_sent : false,
    mobile : '',
    email : '',
    resume : '',
  transition: 'dialog-bottom-transition'
    }),
  }
</script>
<style>
body .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #d7d7d7 !important;
}
</style>
