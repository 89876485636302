<template>
  <Job />
</template>

<script>
  import Job from '../components/Job'

  export default {
    name: 'Jobs',

    components: {
      Job,
    },
  }
</script>
